<template>
  <div
    style="
      height: calc(92vh);
      width: 100%;
      background: var(--v-greyRaised-base) !important;
    "
    class="innerContent"
  >
    <v-card flat style="height: 100%; width: 100%">
      <v-card-text>
        <v-row class="pa-2">
          <v-col cols="12" sm="6" md="3">
            <v-card class="infoCards" flat>
              <v-subheader style="font-size: 14px"
                ><v-icon class="mr-2" small>business</v-icon>
                Company Information
                <v-spacer></v-spacer>
                <v-btn @click="editCompanyModal = true" icon
                  ><v-icon small>edit</v-icon></v-btn
                ></v-subheader
              >
              <v-divider></v-divider>
              <v-card-text class="pb-0 pt-1" id="company-info">
                <v-row dense class="my-2" justify="center">
                  <v-col
                    cols="12"
                    sm="4"
                    class="text-center my-0 py-0"
                  >
                    <div>
                      <v-card
                        flat
                        style="background-color: transparent !important"
                      >
                        <v-card-text class="my-0 py-0" :key="logoKey">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-avatar
                                tile
                                v-on="on"
                                size="85"
                                :color="
                                  item.logo
                                    ? 'white'
                                    : 'var(--v-background-base)'
                                "
                                class="pa-1"
                                :loading="loadingLogo"
                                :style="{
                                  border: item.logo
                                    ? 'none'
                                    : '1px dotted grey',
                                  cursor: 'pointer',
                                }"
                                @click="openFileExplorer('logo')"
                              >
                                <v-img
                                  v-if="item.logo"
                                  :src="item.logo"
                                  height="100px"
                                  contain
                                >
                                </v-img>
                                <span v-else style="font-size: 12px"
                                  ><v-icon>add</v-icon></span
                                >
                              </v-avatar>
                            </template>
                            <span v-if="item.logo">Edit Logo</span
                            ><span v-else>Add Logo</span>
                          </v-tooltip>

                          <v-row
                            justify="center"
                            style="position: absolute; top: 10px; right: 50px"
                            v-if="item.logo"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="clearImage('logo')"
                                  color="red"
                                >
                                  <v-icon>cancel</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove Logo</span>
                            </v-tooltip>
                          </v-row>
                          <input
                            id="logoUpload"
                            type="file"
                            hidden
                            accept="image/jpeg, image/jpg, image/png"
                            @change="logoChange($event)"
                          />
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <!-- <div v-if="loadingIcon">
                        <v-skeleton-loader class="mx-auto" max-width="max" type="card" transition="fade-transition">
                        </v-skeleton-loader>
                      </div> -->
                    <div>
                      <v-card
                        flat
                        style="background-color: transparent !important"
                      >
                        <v-card-text class="my-0 py-0" :key="iconKey">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-avatar
                                tile
                                v-on="on"
                                size="85"
                                :color="
                                  item.icon
                                    ? 'white'
                                    : 'var(--v-background-base)'
                                "
                                class="pa-1"
                                :style="{
                                  border: item.icon
                                    ? 'none'
                                    : '1px dotted grey',
                                  cursor: 'pointer',
                                }"
                                @click="openFileExplorer('icon')"
                              >
                                <v-img
                                  v-if="item.icon"
                                  :src="item.icon"
                                  height="100px"
                                  contain
                                >
                                </v-img>
                                <span
                                  v-else-if="!loadingIcon"
                                  style="font-size: 12px"
                                  ><v-icon>add</v-icon></span
                                >
                                <v-progress-circular
                                  v-else
                                  color="secondary"
                                  size="24"
                                  indeterminate
                                ></v-progress-circular>
                              </v-avatar>
                            </template>
                            <span v-if="item.icon">Edit Icon</span
                            ><span v-else>Add Icon</span>
                          </v-tooltip>

                          <v-row
                            justify="center"
                            style="position: absolute; top: 10px; right: 50px"
                            v-if="item.icon"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="clearImage('icon')"
                                  color="red"
                                >
                                  <v-icon>cancel</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove Icon</span>
                            </v-tooltip>
                          </v-row>
                          <input
                            id="iconUpload"
                            type="file"
                            hidden
                            accept="image/jpeg, image/jpg, image/png"
                            @change="iconChange($event)"
                          />
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <!-- <div v-if="loadingIcon">
                        <v-skeleton-loader class="mx-auto" max-width="max" type="card" transition="fade-transition">
                        </v-skeleton-loader>
                      </div> -->
                    <div>
                      <v-card
                        flat
                        style="background-color: transparent !important"
                      >
                        <v-card-text class="my-0 py-0" :key="stampKey">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-avatar
                                tile
                                v-on="on"
                                size="85"
                                :color="
                                  item.stamp
                                    ? 'white'
                                    : 'var(--v-background-base)'
                                "
                                class="pa-1"
                                :style="{
                                  border: item.stamp
                                    ? 'none'
                                    : '1px dotted grey',
                                  cursor: 'pointer',
                                }"
                                @click="openFileExplorer('stamp')"
                              >
                                <v-img
                                  v-if="item.stamp"
                                  :src="item.stamp"
                                  height="100px"
                                  contain
                                >
                                </v-img>
                                <span
                                  v-else-if="!loadingStamp"
                                  style="font-size: 12px"
                                  ><v-icon>add</v-icon></span
                                >
                                <v-progress-circular
                                  v-else
                                  color="secondary"
                                  size="24"
                                  indeterminate
                                ></v-progress-circular>
                              </v-avatar>
                            </template>
                            <span v-if="item.stamp">Edit Stamp</span
                            ><span v-else>Add Stamp</span>
                          </v-tooltip>

                          <v-row
                            justify="center"
                            style="position: absolute; top: 10px; right: 50px"
                            v-if="item.stamp"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  icon
                                  @click="clearImage('stamp')"
                                  color="red"
                                >
                                  <v-icon>cancel</v-icon>
                                </v-btn>
                              </template>
                              <span>Remove Stamp</span>
                            </v-tooltip>
                          </v-row>
                          <input
                            id="stampUpload"
                            type="file"
                            hidden
                            accept="image/jpeg, image/jpg, image/png"
                            @change="stampChange($event)"
                          />
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.name
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Registered Name</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="item.alias">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.alias
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px"
                            >Alias</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.logliveCode
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >LogLive Code</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px"
                            >Financial Year End</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                label
                                small
                                style="font-size: 12px"
                                v-on="on"
                              >
                                {{ months[item.financialYearEnd - 1] }}
                                <v-icon small right>expand_more</v-icon>
                              </v-chip>
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-for="(month, index) in months"
                                :key="index"
                                @click="
                                  (item.financialYearEnd = index + 1),
                                    updateOrganisation()
                                "
                                style="height: 30px"
                              >
                                <v-list-item-title style="font-size: 12px">{{
                                  month
                                }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-list-item-action>
                      </v-list-item>
                      <v-list-item v-if="item.registrationNumber">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.registrationNumber
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Company Registration No.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="item.taxNumber">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.taxNumber
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Tax Number / UTR Number</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="item.customsCode">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.customsCode
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Customs Code</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="item.vatNumber">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.vatNumber
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >VAT No.</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="item.paltrackCode">
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">{{
                            item.paltrackCode
                          }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px"
                            >Paltrack Code</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item @click="editCommercialTerms = true">
                        <v-list-item-content>
                          <v-list-item-title
                            style="font-size: 12px"
                            class="text-wrap"
                            v-if="item.commercialTerms"
                          >
                            {{ item.commercialTerms }}
                          </v-list-item-title>
                          <v-list-item-title style="font-size: 12px" v-else>
                            -
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 11px">
                            Account Sale General Terms
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-card class="infoCards" flat>
              <v-subheader style="font-size: 14px"
                ><v-icon class="mr-2" small>contact_mail</v-icon>
                Contact Information
                <v-spacer></v-spacer>
                <v-btn @click="editContactModal = true" icon
                  ><v-icon small>edit</v-icon></v-btn
                >
              </v-subheader>
              <v-divider> </v-divider>
              <v-card-text class="my-0 py-0">
                <v-list dense subheader>
                  <v-list-item v-if="item.emailAddress">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">{{
                        item.emailAddress
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Primary Email Address</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.telephoneNumber">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">{{
                        item.telephoneNumber
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Primary Contact No.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.emailAddressAlt">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">{{
                        item.emailAddressAlt
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Alternative Email Address</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="item.telephoneNumberAlt">
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px">{{
                        item.telephoneNumberAlt
                      }}</v-list-item-title>
                      <v-list-item-subtitle style="font-size: 11px"
                        >Alternative Contact No.</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.commercialEmailAddress }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Commercial Email Address
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="3">
            <v-card flat class="infoCards">
              <v-subheader style="font-size: 14px"
                ><v-icon small class="mr-2">location_on</v-icon> Listed
                Addresses
                <v-spacer></v-spacer>
                <v-btn
                  @click="addAddress"
                  icon
                  color="primary"
                  id="add-address-button"
                >
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </v-subheader>
              <v-divider></v-divider>
              <v-card-text>
                <v-list dense class="my-0 py-0">
                  <v-list-item
                    v-for="address in item.organisationAddresses"
                    :key="address.id"
                  >
                    <v-list-item-content>
                      <div style="margin-bottom: 10px">
                        <u style="font-size: 14px">{{ address.alias }}</u>
                        <v-icon small right v-if="address.isHQ" color="primary"
                          >high_quality
                        </v-icon>
                        <v-icon
                          small
                          right
                          v-if="address.isDeliveryAddress"
                          color="primary"
                        >
                          local_shipping</v-icon
                        >
                        <v-icon
                          small
                          right
                          v-if="address.isDocsAddress"
                          color="primary"
                        >
                          description</v-icon
                        >
                      </div>
                      <span
                        v-if="address.addressLine1"
                        style="font-size: 12px"
                        >{{ address.addressLine1 }}</span
                      >
                      <span
                        v-if="address.addressLine2"
                        style="font-size: 12px"
                        >{{ address.addressLine2 }}</span
                      >
                      <span
                        v-if="address.addressLine3"
                        style="font-size: 12px"
                        >{{ address.addressLine3 }}</span
                      >
                      <span
                        v-if="address.addressLine4"
                        style="font-size: 12px"
                        >{{ address.addressLine4 }}</span
                      >
                      <span v-if="address.town" style="font-size: 12px">{{
                        address.town
                      }}</span>
                      <span
                        v-if="address.subdivision"
                        style="font-size: 12px"
                        >{{ address.subdivision }}</span
                      >
                      <span v-if="address.country" style="font-size: 12px">{{
                        address.country
                      }}</span>
                      <span style="font-size: 12px">{{
                        address.postalCode
                      }}</span>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-row>
                        <v-btn
                          id="edit-address-button"
                          icon
                          @click="editAddress(address)"
                        >
                          <v-icon small>edit</v-icon>
                        </v-btn>
                        <v-btn
                          id="remove-address-button"
                          icon
                          color="danger"
                          @click="removeAddress(address)"
                        >
                          <v-icon small>delete</v-icon>
                        </v-btn>
                      </v-row>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="3">
            <v-card flat class="infoCards">
              <v-subheader style="font-size: 14px"
                ><v-icon class="mr-2" small>hub</v-icon> Network Functions
                <v-spacer></v-spacer>
                <v-btn
                  v-if="functionChanged"
                  icon
                  color="primary"
                  :loading="savingNetworkFunctions"
                  @click="saveNetworkFunctions"
                  ><v-icon>save</v-icon></v-btn
                >
              </v-subheader>
              <!-- <v-card-subtitle></v-card-subtitle> -->
              <v-divider></v-divider>
              <v-card-text id="network-functions" class="my-0 py-0">
                <v-list dense :key="updateFunction" class="my-0 py-0">
                  <v-list-item @click="change('bank')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.bank">
                        <v-icon small>account_balance</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>account_balance</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Bank</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.bank" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('buyer')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.buyer">
                        <v-icon small>shopping_cart</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>shopping_cart</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Buyer</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.buyer" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('coldstore')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.coldstore">
                        <v-icon small>store</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>store</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Coldstore</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.coldstore" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('consignee')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.consignee">
                        <v-icon small>call_received</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>call_received</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Consignee</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.consignee" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('forwarder')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.forwarder">
                        <v-icon small>fast_forward</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>fast_forward</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Forwarder</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.forwarder" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('notifyParty')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.notifyParty"
                      >
                        <v-icon small>notifications_active</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>notifications_active</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Notify Party</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.notifyParty" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('producer')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.producer">
                        <v-icon small>factory</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>factory</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Producer</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.producer" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('shipper')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar color="primary" size="30" v-if="item.shipper">
                        <v-icon small>north_east</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>north_east</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Shipper</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.shipper" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('shippingLine')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.shippingLine"
                      >
                        <v-icon small>directions_boat</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>directions_boat</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Shipping Line</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.shippingLine" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('governingBody')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.governingBody"
                      >
                        <v-icon small>gavel</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>gavel</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Governing Body</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.governingBody" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('pluginProvider')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.pluginProvider"
                      >
                        <v-icon small>extension</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>extension</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Plugin Provider</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.pluginProvider" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('transporter')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.transporter"
                      >
                        <v-icon small>local_shipping</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>local_shipping</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >Transporter</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.transporter" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>

                  <v-list-item @click="change('vasProvider')" style="height: 30px">
                    <v-list-item-action>
                      <v-avatar
                        color="primary"
                        size="30"
                        v-if="item.vasProvider"
                      >
                        <v-icon small>settings_suggest</v-icon>
                      </v-avatar>
                      <v-avatar size="30" v-else>
                        <v-icon small>settings_suggest</v-icon>
                      </v-avatar>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title style="font-size: 12px"
                        >VAS Provider</v-list-item-title
                      >
                      <v-list-item-title style="font-size: 11px"
                        >Value Added Service Provider</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon small v-if="item.vasProvider" color="success"
                        >check_circle</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <!-- <v-card outlined class="mx-auto" style="max-height:75vh; overflow-y:auto">

                <v-subheader style="font-size: 16px"><v-icon class="mr-2"
                    color="secondary">integration_instructions</v-icon> System Integration
                  <v-spacer></v-spacer>
                  <v-btn v-if="integrationChange" icon color="primary" :loading="savingIntegration" @click="saveIntegration"><v-icon>save</v-icon></v-btn>
                </v-subheader>
                <v-divider></v-divider>
                <v-card-text id="system-integration">
                  <v-subheader style="font-size: 12px">
                    API Access Key: <v-spacer></v-spacer>
                    <span class="font-italic" v-if="item.apiAccessKey">{{ item.apiAccessKey }}</span>
                    <v-spacer></v-spacer>
                    <v-tooltip top v-if="!item.apiAccessKey">
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="secondary" icon @click="generateCredentials()"
                          small><v-icon>add</v-icon></v-btn>
                      </template>
                      <span>Generate API Access Key</span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.apiAccessKey">
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" small icon @click="copyData(item.apiAccessKey)" ><v-icon small>content_copy</v-icon></v-btn>
                            </template>
                            <span>Copy Content</span>
                        </v-tooltip>
                    </v-subheader>
                    <v-subheader style="font-size: 12px">                  
                      <v-switch @change="integrationChange = true" label="Share Data" dense v-model="item.shareData"></v-switch>
                    </v-subheader>
                </v-card-text>
              </v-card> -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- </v-card-text>

    </v-card> -->
    <!-- <v-btn color="greyBase" class="infoButton" elevation="2" fab fixed bottom right style="text-transform: none;"
      @click="infoStartGuide">
      <v-icon large color="white">question_mark</v-icon>
    </v-btn> -->

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn
          @click="infoStartGuide"
          icon
          style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0"
          ><v-icon color="primary" size="30">close</v-icon></v-btn
        >
        <v-card-title class="d-flex justify-center">
          Welcome to your Company Info Overview
        </v-card-title>
        <v-card-text>
          <span>
            <p>Bobo</p>
            <p>
              FRIKO

              <br />This is where we start! Here is where you manage the overall
              profile details of your company. <br />Anyone engaging with you
              will be using the relevant and updated <br />information as
              maintained by you. No more incorrect addresses <br />or
              registration information used based on outdated systems.
            </p>
            If you would like learn how to load POI's into the system, click the
            button below to start the tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog to manage Addresses -->
    <v-dialog
      v-model="addressModal"
      persistent
      width="800"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <AddressModalVue
        :item="addressItem"
        @addKey="addressKeyPlus()"
        @close="closeAddressModal()"
        @save="saveAddress"
        transition="scale-transition"
        :dialogVisible="addressModal"
      />
    </v-dialog>

    <v-dialog
      v-model="editCompanyModal"
      persistent
      width="1200px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Edit Company </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateOrganisation()"
            :loading="savingOrganisation"
            icon
            color="secondary"
            class="mr-2"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="editCompanyModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="pb-0">
              <v-text-field
                dense
                v-model="item.name"
                label="Registered Company Name"
                required
                outlined
                persistent-placeholder
                prepend-inner-icon="business"
              >
              </v-text-field>
              <v-autocomplete
                dense
                v-model="selectedCountry"
                :items="countryNames"
                item-text="name"
                item-value="name"
                return-object
                label="Country"
                outlined
              >
                <template v-slot:selection="data">
                  <v-avatar size="32" class="mr-2" v-if="data.item">
                    <v-img
                      v-if="data.item.countryCode"
                      contain
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-avatar>
                  <span v-if="data.item">
                    {{ data.item.name }}
                  </span>
                </template>
                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <v-img
                      contain
                      v-if="data.item.countryCode"
                      :src="`https://cdn.loglive.io/flags/4x3/${data.item.countryCode.toLowerCase()}.svg`"
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.registrationNumber"
                prepend-inner-icon="numbers"
                label="Company Reg. Number"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.taxNumber"
                label="Tax Number / UTR Number"
                prepend-inner-icon="numbers"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.customsCode"
                prepend-inner-icon="numbers"
                label="Customs Code"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.paltrackCode"
                prepend-inner-icon="numbers"
                label="Paltrack Code"
                required
                outlined
              >
              </v-text-field>

              <!-- <v-checkbox dense v-model="item.isGroup" label="Is this Company part of a Group?" outlined>
                      </v-checkbox> -->
              <v-checkbox
                dense
                v-model="item.apeStatus"
                label="Is this an APE Company?"
                outlined
              >
              </v-checkbox>
            </v-col>
            <v-col>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.titanFBOCode"
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                label="Titan FBO Code"
                required
                outlined
              >
              </v-text-field>

              <v-text-field
                persistent-placeholder
                dense
                hide-details
                v-if="
                  selectedCountry &&
                  (selectedCountry.regionCode == 'EUN' ||
                    selectedCountry.countryCode == 'GB')
                "
                prepend-inner-icon="numbers"
                v-model="item.sicCode"
                label="SIC Code"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                dense
                v-model="item.euEori"
                prepend-inner-icon="numbers"
                label="EU EORI Number"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                dense
                v-model="item.xiEori"
                prepend-inner-icon="numbers"
                label="XI EORI Number"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                dense
                v-model="item.gbEori"
                prepend-inner-icon="numbers"
                label="GB EORI Number"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                dense
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                v-model="item.solasAccreditationUser"
                label="SOLAS User"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                dense
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                v-model="item.solasAccreditationNo"
                label="SOLAS Accreditation"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.DALRRDRefNumber"
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                label="DALRRD Ref. Number"
                required
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col class="pb-0">
              <v-text-field
                dense
                v-model="item.alias"
                label="Display Name / Alias"
                required
                outlined
                persistent-placeholder
                prepend-inner-icon="business"
              >
              </v-text-field>
              <v-list-item style="height: auto">
                <v-list-item-content>
                  <v-list-item-title style="font-size: 20px; line-height: 1.4">
                    {{ item.logliveCode }}
                  </v-list-item-title>
                  <v-list-item-subtitle style="font-size: 12px">
                    LogLive Code
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.companyType"
                label="Company Type"
                required
                prepend-inner-icon="business"
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-model="item.vatNumber"
                label="Vat Number"
                prepend-inner-icon="numbers"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                v-model="item.cbrId"
                label="CBR ID Number"
                required
                outlined
                prepend-inner-icon="numbers"
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                v-model="item.nNumber"
                label="PPECB N-Number"
                required
                outlined
              >
              </v-text-field>

              <v-text-field
                persistent-placeholder
                dense
                v-if="selectedCountry && selectedCountry.countryCode == 'ZA'"
                prepend-inner-icon="numbers"
                v-model="item.titanFBOCode"
                label="Titan FBO Code"
                required
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-if="
                  selectedCountry &&
                  (selectedCountry.regionCode == 'EUN' ||
                    selectedCountry.countryCode == 'GB')
                "
                prepend-inner-icon="numbers"
                v-model="item.xiEori"
                label="XI EORI"
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-if="
                  selectedCountry &&
                  (selectedCountry.regionCode == 'EUN' ||
                    selectedCountry.countryCode == 'GB')
                "
                prepend-inner-icon="numbers"
                v-model="item.euEori"
                label="EU EORI"
                outlined
              >
              </v-text-field>
              <v-text-field
                persistent-placeholder
                dense
                v-if="
                  selectedCountry &&
                  (selectedCountry.regionCode == 'EUN' ||
                    selectedCountry.countryCode == 'GB')
                "
                prepend-inner-icon="numbers"
                v-model="item.gbEori"
                label="GB EORI"
                outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editContactModal"
      persistent
      width="400px"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-card>
        <v-toolbar flat color="transparent">
          <v-toolbar-title style="font-size: 16px"> Edit Contact Information </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            @click="updateOrganisation()"
            :loading="savingOrganisation"
            icon
            color="secondary"
            class="mr-2"
            ><v-icon>save</v-icon></v-btn
          >
          <v-btn text @click="editContactModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            persistent-placeholder
            dense
            v-model="item.emailAddress"
            prepend-inner-icon="mail"
            label="Primary Email Address"
            required
            outlined
          >
          </v-text-field>

          <v-text-field
            persistent-placeholder
            dense
            v-model="item.telephoneNumber"
            label="Primary Contact No."
            required
            outlined
            prepend-inner-icon="phone"
          >
          </v-text-field>
          <v-text-field
            persistent-placeholder
            dense
            v-model="item.emailAddressAlt"
            prepend-inner-icon="mail"
            label="Alternative Email Address"
            required
            outlined
          >
          </v-text-field>
          <v-text-field
            persistent-placeholder
            dense
            v-model="item.telephoneNumberAlt"
            label="Alternative Contact No."
            required
            outlined
            prepend-inner-icon="phone"
          >
          </v-text-field>
          <v-text-field
            persistent-placeholder
            dense
            v-model="item.commercialEmailAddress"
            prepend-inner-icon="mail"
            label="Commercial Email Address"
            required
            outlined
          >
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editCommercialTerms" width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-size: 16px"> Edit Account Sale General Terms </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            color="secondary"
            @click="updateOrganisation()"
            :loading="savingOrganisation"
          >
            <v-icon>save</v-icon>
          </v-btn>
          <v-btn icon @click="editCommercialTerms = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            v-model="item.commercialTerms"
            outlined
            rows="10"
            auto-grow
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import Driver from "driver.js";
import "driver.js/dist/driver.min.css";
import steps from "./Steps/infoSteps";


import {
  gmapApi,
  // Map
} from "vue2-google-maps";
import AddressModalVue from "../SystemComponents/AddressModal.vue";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  components: {
    AddressModalVue,
  },
  data: () => ({
    steps: null,
    integrationChange: false,
    savingIntegration: false,
    driver: null,
    addressModal: false,
    addressKey: 0,
    logCodeKey: 0,
    addressItem: {},
    center: {
      lng: 18.427059,
      lat: -33.919473,
    },
    editCommercialTerms: false,
    editCompanyModal: false,
    editContactModal: false,
    functionChanged: false,
    key: 0,
    iconKey: 2000,
    loading: false,
    loadingIcon: false,
    loadingLogo: false,
    loadingStamp: false,
    logoKey: 1000,
    stampKey: 1100,
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    searchSystemModules: "",
    logoList: [],
    iconList: [],
    stampList: [],
    tabRefresh: 0,
    tempModules: [],
    zoom: 12,
    countries: {
      total: 0,
      data: [],
    },
    countryNames: [],
    selectedCountry: { name: "" },
    infoStart: false,
    savingOrganisation: false,
    savingNetworkFunctions: false,
    updateFunction: 100,
  }),
  computed: {
    google: gmapApi,
    filterModules() {
      let result = this.modules;
      if (this.searchSystemModules) {
        result = result.filter((x) =>
          x.name.toLowerCase().includes(this.searchSystemModules.toLowerCase())
        );
      }
      return result;
    },
  },
  watch: {
    selectedCountry: {
      async handler(val) {
        if (val) {
          this.item.country = val.name;
          this.item.countryCode = val.countryCode;
          this.item.regionCode = val.regionCode;
        }
      },
    },
  },
  async created() {
    this.loadOrganisation();
    await  this.getSteps();
  },
  async mounted() {
    this.driver = new Driver({
      animate: false,
    });
  },
  methods: {
    async getSteps() {
        let guide = await this.$API.getGuideSteps({
            guideId: 'Management/Info/Steps',
        });
        this.steps = guide.steps
    },
    addAddress() {
      this.addressItem = {
        modalType: "Add",
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        addressLine4: "",
        country: this.item.country,
        countryCode: this.item.countryCode,
      };
      setTimeout(() => {
        this.$refs.mapRef.$mapPromise.then(() => {
          this.initMapAutocomplete();
        });
      }, 2000);
      this.addressModal = true;
    },
    clearFilter() {
      this.$refs.userTable.clearFilter();
      this.userParams.filter = {};
      // this.getUsers()
    },
    async clearImage(type) {
      this.item[type] = null;
      this.savingOrganisation = true;
      await this.$API.updateOrganisation({
        id: this.item.id,
        [type]: null,
      });
      this.savingOrganisation = false;
      this.$message.success("Successfully updated!");
    },
    clearUsers() {
      this.tempUsers = [];
      this.userDialog = false;
    },
    clearModules() {
      this.tempModules = [];
      this.moduleDialog = false;
    },
    createFilter(queryString) {
      return (link) => {
        return link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    editAddress(item) {
      this.addressItem = item;
      if (!this.addressItem.country) {
        this.addressItem.country = this.item.name;
        this.addressItem.countryCode = this.item.countryCode;
      }
      this.addressItem.modalType = "Edit";
      if (this.addressItem.position) {
        this.center = {
          lat: this.addressItem.position.coordinates[1],
          lng: this.addressItem.position.coordinates[0],
        };
      }
      this.addressModal = true;
    },
    closeAddressModal() {
      this.addressModal = false;
      this.addressItem = {};
    },

    async process(x, ref) {
      if (ref == "icon") {
        this.avatarFile = x.target.files[0];
        this.fileSrc = await URL.createObjectURL(x.target.files[0]);
      }
      if (ref == "logo") {
        this.idFile = x.target.files[0];
        this.fileSrc = await URL.createObjectURL(x.target.files[0]);
      }
    },

    openFileExplorer(val) {
      if (val == "logo") {
        document.getElementById("logoUpload").click();
      }
      if (val == "icon") {
        document.getElementById("iconUpload").click();
      }
      if (val == "stamp") {
        document.getElementById("stampUpload").click();
      }
    },

    toDataUrl(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },

    checkImageDimensions(url, callback) {
      const img = new Image();
      img.onload = function () {
        callback(this.width, this.height);
      };
      img.src = url;
    },

    async logoChange(x) {
      let file = x.target.files[0];
      this.loadingLogo = true;
      this.logoKey++;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.checkImageDimensions(url, (width, height) => {
        if (!(width == 192 && height == 192)) {
          this.$message.error(
            `Logo must be 192x192px, currently ${width}x${height}`
          );
          this.logoKey++;
          this.loadingLogo = false;
          return;
        } else {
          this.toDataUrl(url, (data) => {
            this.submitUpload({
              name: file.name,
              data: data,
              type: file.type,
            });
          });
        }
      });
    },

    async iconChange(x) {
      let file = x.target.files[0];
      this.loadingIcon = true;
      this.iconKey++;
      let url = await URL.createObjectURL(x.target.files[0]);
      this.checkImageDimensions(url, (width, height) => {
        if (!(width == 64 && height == 64)) {
          this.$message.error(
            `Icon must be 64x64px, currently ${width}x${height}`
          );
          this.iconKey++;
          this.loadingIcon = false;
          return;
        } else {
          this.toDataUrl(url, (data) => {
            this.submitIconUpload({
              name: file.name,
              data: data,
              type: file.type,
            });
          });
        }
      });
    },
    async stampChange(x) {
      let file = x.target.files[0];
      this.loadingStamp = true;
      this.stampKey++;
      let url = await URL.createObjectURL(x.target.files[0]);
          this.toDataUrl(url, (data) => {
            this.submitStampUpload({
              name: file.name,
              data: data,
              type: file.type,
            });
          })        
    },
    async initMapAutocomplete() {
      console.log("Autocomplete initiated");
      const input = document.getElementById("pac-input");
      const autocomplete = new this.google.maps.places.Autocomplete(input, {});
      this.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        async () => {
          const place = autocomplete.getPlace();
          this.addressItem.position = {
            type: "Point",
            coordinates: [
              place.geometry.location.lng(),
              place.geometry.location.lat(),
            ],
          };
          this.center = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };

          for (let i = 0; i < place.address_components.length; i++) {
            if (place.address_components[i].types.includes("street_number")) {
              this.addressItem.addressLine1 =
                place.address_components[i].short_name;
            }
            if (place.address_components[i].types.includes("route")) {
              this.addressItem.addressLine1 +=
                " " + place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("sublocality")) {
              this.addressItem.addressLine2 =
                place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types.includes(
                "administrative_area_level_2"
              )
            ) {
              this.addressItem.addressLine2 +=
                ", " + place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types.includes(
                "administrative_area_level_1"
              )
            ) {
              this.addressItem.addressLine3 =
                place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("country")) {
              this.addressItem.addressLine4 =
                place.address_components[i].long_name;
            }
            if (place.address_components[i].types.includes("postal_code")) {
              this.addressItem.postalCode =
                place.address_components[i].short_name;
            }
            this.zoom = 16;
          }
          this.key++;
          this.addressKey++;
        }
      );
    },
    addressKeyPlus() {
      this.addressKey++;
    },
    change(type) {
      this.item[type] = !this.item[type];
      this.updateFunction++;
    },
    async saveAddress(obj) {
      if (!this.item.organisationAddresses.some((e) => e.id === obj.id)) {
        this.item.organisationAddresses.push(obj);
      }
      this.addressModal = false;
      this.addressItem = {};
      this.$message.success("Address saved successfully!");
    },

    async submitUpload(obj) {
      this.loadingLogo = true;
      await this.$API.uploadLogo(obj);
      this.loadOrganisation();
      this.loadingLogo = false;
      this.logoKey++;
    },
    async submitIconUpload(obj) {
      this.loadingIcon = true;
      await this.$API.uploadIcon(obj);
      this.loadOrganisation();
      this.loadingIcon = false;
      this.iconKey++;
    },
    async submitStampUpload(obj) {
      this.loadingStamp = true;
      await this.$API.uploadStamp(obj);
      this.loadOrganisation();
      this.loadingStamp = false;
      this.stampKey++;
    },
    async loadOrganisation() {
      this.loading = true;
      this.item = await this.$API.getOrganisation();
      await this.loadCountries();
      this.loading = false;
    },
    async loadCountries() {
      this.countries = await this.$API.searchCountries();
      for (let i = 0; i < this.countries.data.length; i++) {
        this.countryNames.push({
          name: this.countries.data[i].name,
          countryCode: this.countries.data[i].code,
          regionCode: this.countries.data[i].regionCode,
        });
      }
      if (this.item.country && this.item.countryCode) {
        this.selectedCountry = {
          name: this.item.country,
          countryCode: this.item.countryCode,
          regionCode: this.item.regionCode,
        };
      }
    },
    removeAddress(item) {
      this.$confirm(
        `Are you sure you want to remove this address?`,
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          await this.$API.updateOrganisationAddress({
            id: item.id,
            isActive: false,
            isDeleted: true,
          });
          this.item.organisationAddresses.splice(
            this.item.organisationAddresses.indexOf(item),
            1
          );
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Delete canceled",
          });
        });
    },
    async saveNetworkFunctions() {
      this.savingNetworkFunctions = true;
      await this.$API.updateOrganisation({
        id: this.item.id,
        coldstore: this.item.coldstore,
        bank: this.item.bank,
        transporter: this.item.transporter,
        shippingLine: this.item.shippingLine,
        shipper: this.item.shipper,
        consignee: this.item.consignee,
        buyer: this.item.buyer,
        notifyParty: this.item.notifyParty,
        governingBody: this.item.governingBody,
        producer: this.item.producer,
        pluginProvider: this.item.pluginProvider,
        vasProvider: this.item.vasProvider,
        forwarder: this.item.forwarder,
      });
      this.$message.success("Successfully updated!");
      this.savingNetworkFunctions = false;
      this.functionChanged = false;
    },
    async updateOrganisation() {
      // this.loading = true
      this.savingOrganisation = true;
      // if (this.item.fixedCode === false) {
      //   if (this.item.logliveCode) {
      //     this.item.logliveCode = this.item.countryCode ? this.item.countryCode + this.item.logliveCode : null
      //     this.item.fixedCode = this.item.countryCode ? true : false
      //   } else {
      //     this.item.logliveCode = this.item.countryCode ? this.item.countryCode : null
      //     this.item.fixedCode = this.item.countryCode ? true : false
      //   }
      // }
      // else {
      //   return
      // }
      await this.$API.updateOrganisation(this.item);
      this.$message.success("Successfully updated!");
      // this.loading = false
      this.editCommercialTerms = false;
      this.savingOrganisation = false;
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false;
      e.stopPropagation();

      this.driver.defineSteps(steps);
      this.driver.start();

    },
    async generateCredentials() {
      this.integrationChange = true;
      this.item.apiAccessKey = crypto.randomUUID();
    },
    copyData(data) {
      navigator.clipboard.writeText(data);
      this.snackbar = true;
    },
    async saveIntegration() {
      this.savingIntegration = true;
      await this.$API.updateOrganisation({
        apiAccessKey: this.item.apiAccessKey,
        shareData: this.item.shareData,
      });
      this.$message.success("Successfully updated!");
      this.savingIntegration = false;
      this.integrationChange = false;
    },
  },
};
</script>

<style scoped>
* {
  font-size: 12px;
  --darkLayer: var(--v-greyDarker-base) !important;
  --lighterLayer: var(--v-greyRaised-lighten1) !important;
}
.tabContent ::v-deep .v-tabs-bar__content {
  background: var(--darkLayer) !important;
}
.infoCards {
  background: var(--lighterLayer) !important ;
  contain: content;
  border-radius: 10px;
}
.innerContent {
  max-height: calc(100vh - 36px) !important;
  height: 100%;
  width: 100%;
  contain: content;
  overflow-y: scroll;
  background: var(--v-greyRaised-base) !important;
}
</style>
