const steps = [
  {
    element: "#company-info",
    popover: {
      title: "Company Info",
      description:
        "This is where you will enter your company information into the designated input fields",
      position: "right",
    },
  },
  {
    element: "#contact-info",
    popover: {
      title: "Contact Info",
      description:
        "This is where you will enter your contact information into the designated input fields",
      position: "right",
    },
  },
  {
    element: "#add-address-button",
    popover: {
      title: "Add Address",
      description: "Allows you to create an address for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#edit-address-button",
    popover: {
      title: "Edit Address",
      description: "Allows you to edit an address for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#remove-address-button",
    popover: {
      title: "Remove Address",
      description: "Allows you to remove an address for your organisation",
      position: "bottom",
    },
  },
  {
    element: "#upload-logo",
    popover: {
      title: "Upload Logo",
      description: "Allows you to upload your organisation's logo",
      position: "bottom",
    },
  },
  {
    element: "#upload-icon",
    popover: {
      title: "Upload Icon",
      description: "Allows you to upload your organisation's icon",
      position: "bottom",
    },
  },
  {
    element: "#network-functions",
    popover: {
      title: "Network Functions",
      description: "Allows you to choose your organisation's network functions",
      position: "left",
    },
  },
  {
    element: "#save-org-button",
    popover: {
      title: "Save Organisation",
      description: "Saves your organisations information",
      position: "left",
    },
  },
];

export default steps;
